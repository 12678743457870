import { useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";

import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Row,
  Col,
} from "reactstrap";
import { RouteProps } from "routes";
import { actionLogout } from "actions/identity/action-logout";
import {
  Sidebar as SidebarPro,
  Menu,
  MenuItem,
  SubMenu,
} from "react-pro-sidebar";

import "./sidebar.style.css";
const Sidebar: React.FC = (props: any) => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  // verifies if routeName is the one active (in browser input)
  // const activeRoute = (routeName) => {
  //   return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  // };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes: RouteProps[]) => {
    return routes
      .filter((prop) => prop?.isHidden != true)
      .map((prop, key) => {
        return (
          <NavItem key={key} className="mb-auto">
            <NavLink
              to={prop.layout + prop.path}
              tag={NavLinkRRD}
              onClick={closeCollapse}
            >
              <i className={prop.icon} />
              {prop.name}
            </NavLink>
          </NavItem>
        );
      });
  };

  const createLinksReactSidebarPro = (routes: RouteProps[]) => {
    return routes
      .filter((prop) => prop?.isHidden != true)
      .map((prop, key) => {
        if (!prop.children) {
          return (
            <MenuItem icon={<i className={prop.icon} />}>
              <NavLink
                to={prop.layout + prop.path}
                tag={NavLinkRRD}
                onClick={closeCollapse}
              >
                {prop.name}
              </NavLink>
            </MenuItem>
          );
        }
        return (
          <SubMenu label={prop.name} icon={<i className={prop.icon} />}>
            {prop.children
              .filter((child) => child?.isHidden != true)
              .map((child, key) => {
                return (
                  <MenuItem>
                    <NavLink
                      to={child.layout + child.path}
                      tag={NavLinkRRD}
                      onClick={closeCollapse}
                    >
                      {child.name}
                    </NavLink>
                  </MenuItem>
                );
              })}
          </SubMenu>
        );
      });
  };

  const { routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white bg-primary-secondary"
      expand="md"
      id="sidenav-main"
    >
      {/* Toggler */}
      <button className="navbar-toggler" type="button" onClick={toggleCollapse}>
        <span className="navbar-toggler-icon" />
      </button>
      {/* Brand */}
      {logo ? (
        <NavbarBrand className="pt-0" {...navbarBrandProps}>
          <img
            alt={logo.imgAlt}
            className="navbar-brand-img"
            src={logo.imgSrc}
            width="120px"
          />
        </NavbarBrand>
      ) : null}
      {/* User */}
      <Nav className="align-items-center d-md-none">
        <UncontrolledDropdown nav>
          <DropdownToggle nav>
            <Media className="align-items-center">
              <span className="avatar avatar-sm rounded-circle">
                <img
                  alt="..."
                  src="https://ui-avatars.com/api/?name=Cleuber Biango&size=40&rounded=true&color=fff&background=1632D2"
                />
              </span>
            </Media>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" end>
            <DropdownItem to="/painel/meus-dados" tag={Link}>
              <i className="ni ni-single-02" />
              <span>Meus Dados</span>
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem href="/auth/login" onClick={(e) => actionLogout()}>
              <i className="ni ni-user-run" />
              <span>Sair</span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
      {/* Collapse */}
      <Collapse navbar isOpen={collapseOpen}>
        {/* Collapse header */}
        <div className="navbar-collapse-header d-md-none">
          <Row>
            {logo ? (
              <Col className="collapse-brand" xs="6">
                {logo.innerLink ? (
                  <Link to={logo.innerLink}>
                    <img
                      className="height-25"
                      alt={logo.imgAlt}
                      src="../../logo.png"
                    />
                  </Link>
                ) : (
                  <a href={logo.outterLink}>
                    <img
                      alt={logo.imgAlt}
                      src="../../logo.png"
                      className="height-25"
                    />
                  </a>
                )}
              </Col>
            ) : null}
            <Col className="collapse-close" xs="6">
              <button
                className="navbar-toggler"
                type="button"
                onClick={toggleCollapse}
              >
                <span />
                <span />
              </button>
            </Col>
          </Row>
        </div>
        {/* <Nav navbar>{createLinks(routes)}</Nav> */}
        <SidebarPro
          rootStyles={{
            color: "#fff",
          }}
          width="231px"
          backgroundColor="transparent"
          // collapsedWidth="4.5rem"
          // breakPoint="md"
          className="navbar-nav"
        >
          <Menu>{createLinksReactSidebarPro(routes)}</Menu>
        </SidebarPro>

        <Nav className="mb-md-1 mt-2" navbar>
          <NavItem>
            <NavLink
              to="/auth/login"
              tag={NavLinkRRD}
              onClick={(e) => actionLogout()}
            >
              <i className="fas fa-person-running" />
              Sair
            </NavLink>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default Sidebar;
