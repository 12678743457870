import { Badge, Col, Row } from "reactstrap";
import {
  ScheduleTypeAlias,
  ScheduleTypeEnum,
  ScheduleTypeLabels,
} from "domain/schedule/enums/schedule-type.enum";
import { dateFormatter } from "helpers/date-formatter";
import { ListAllFinancialStatementItem } from "services/financial-service";
import {
  PaymentTypeEnum,
  PaymentTypeLabels,
} from "domain/schedule/enums/payment-type.enum";
import { labelMaskFormatToReal } from "helpers/format-money";
import {
  ScheduleStatusEnum,
  ScheduleStatusLabels,
} from "domain/schedule/enums/schedule-status.enum";
import {
  ScheduleOriginContactEnum,
  ScheduleOriginContactLabels,
} from "domain/schedule/enums/schedule-origin-contact.enum";

interface FinancialStatementDetailProps {
  data: ListAllFinancialStatementItem | null;
}

export const FinancialStatementDetail: React.FC<
  FinancialStatementDetailProps
> = ({ data }) => {
  return (
    <>
      {data && (
        <>
          <Row className="p-0">
            <Row className="mb-3">
              <Row className="my-2">
                <Col sm="12" md="5" className="form-label  font-weight-500">
                  Nome Profissional
                </Col>
                <Col sm="12" md="7">
                  {data.doctor_name}
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm="12" md="5" className="form-label  font-weight-500">
                  Nome Paciente
                </Col>
                <Col sm="12" md="7">
                  {data.patient_name}
                </Col>
              </Row>
              <Row className="my-2">
                <Col sm="12" md="5" className="form-label  font-weight-500">
                  Tipo Atendimento
                </Col>
                <Col sm="12" md="7">
                  {/* <Badge
                    color={ScheduleTypeAlias(
                      data.type as unknown as ScheduleTypeEnum
                    )}
                    className="px-3 py-2 fs-15 font-weight-500"
                  >
                    {ScheduleTypeLabels(
                      data.type as unknown as ScheduleTypeEnum
                    )}
                  </Badge> */}
                  {ScheduleTypeLabels(data.type as unknown as ScheduleTypeEnum)}
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm="12" md="5" className="form-label  font-weight-500">
                  Data Atendimento
                </Col>
                <Col sm="12" md="7">
                  {dateFormatter(data.start)}
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm="12" md="5" className="form-label  font-weight-500">
                  Data Pagamento
                </Col>
                <Col sm="12" md="7">
                  {data.paid_at
                    ? dateFormatter(data.paid_at, "dd/MM/yyyy")
                    : "Não Pago"}
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm="12" md="5" className="form-label  font-weight-500">
                  Forma Pagamento
                </Col>
                <Col sm="12" md="7">
                  {data.payment_type
                    ? PaymentTypeLabels(
                        data.payment_type as unknown as PaymentTypeEnum
                      )
                    : "Não Pago"}
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm="12" md="5" className="form-label  font-weight-500">
                  Valor Atendimento
                </Col>
                <Col sm="12" md="7">
                  {labelMaskFormatToReal(String(data.amount))}
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm="12" md="5" className="form-label  font-weight-500">
                  Valor Pago
                </Col>
                <Col sm="12" md="7">
                  {data.payment_type
                    ? labelMaskFormatToReal(String(data.amount_paid))
                    : "Não Pago"}
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm="12" md="5" className="form-label  font-weight-500">
                  Status Atendimento
                </Col>
                <Col sm="12" md="7">
                  {ScheduleStatusLabels(
                    data.status as unknown as ScheduleStatusEnum
                  )}
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm="12" md="5" className="form-label  font-weight-500">
                  Origem Contato
                </Col>
                <Col sm="12" md="7">
                  {data.origin_contact
                    ? ScheduleOriginContactLabels(
                        data.origin_contact as unknown as ScheduleOriginContactEnum
                      )
                    : "Não informado"}
                </Col>
              </Row>
            </Row>
          </Row>
        </>
      )}
    </>
  );
};
