import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from "react";
import Select from "react-select";

import { actionListFinancialStatement } from "actions/financial/action-list-financial-statement";
import { actionDownloadReport } from "actions/dashboard/action-download-report";
import UserIdentity from "domain/user/entities/user-identity";
import { UserRoleEnum } from "domain/user/entities/enums/user-role.enum";

import {
  Badge,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { ListFinancialStatementTable } from "./financial-statement-table";
import {
  ScheduleTypeAlias,
  ScheduleTypeEnum,
  ScheduleTypeLabels,
} from "domain/schedule/enums/schedule-type.enum";
import { labelMaskFormatToReal } from "helpers/format-money";
import { PaginationMeta } from "components/table/custom-table";
import { ListAllFinancialStatementItem } from "services/financial-service";
import { FinancialStatementDetail } from "./financial-statement-detail";
import { dateFormatter } from "helpers/date-formatter";
import { enumIterator } from "helpers/typescript/enum-iterator";
import {
  ScheduleStatusEnum,
  ScheduleStatusLabels,
} from "domain/schedule/enums/schedule-status.enum";
import {
  SelectDoctorState,
  SelectDoctorStateProps,
} from "components/form/input/select-doctor-state-form";
import { getTableType } from "helpers/screen";

const OFFSET_DAYS = 1;
const SEVEN_DAYS = 8;
const FIFTEEN_DAYS = 16;
const THIRTY_DAYS = 31;
const SIXTY_DAYS = 61;

interface FormValues {
  start: Date;
  end: Date;
  limit: number;
  page: number;
  doctor_id?: number;
  status?: any;
}

export interface ListFinancialStatementItemTable {
  id: number;
  patient_name: any;
  doctor_name: any;
  type: any;
  date: any;
  amount_paid: any;
}

const PAGE_SIZE = 20;

export const FinancialStatementContainer = () => {
  const [meta, setMeta] = useState<PaginationMeta | null>(null);
  const [tableType, setTableType] = useState<"card" | "table">(getTableType());

  const [data, setData] = useState<ListFinancialStatementItemTable[]>([]);
  const [rawData, setRawData] = useState<
    ListAllFinancialStatementItem[] | null
  >(null);
  const [selectedSchedule, setSelectedSchedule] =
    useState<ListAllFinancialStatementItem | null>(null);

  const [startDate, setStartDate] = useState(
    new Date(new Date(new Date().setDate(new Date().getDate() - THIRTY_DAYS)))
  );
  const [endDate, setEndDate] = useState(new Date());
  const [formValues, setFormValues] = useState<FormValues>({
    start: new Date(
      new Date(new Date().setDate(new Date().getDate() - THIRTY_DAYS)).setHours(
        21,
        0,
        0,
        0
      )
    ),
    end: new Date(new Date().setHours(20, 59, 0, 0)),
    doctor_id: undefined,
    limit: PAGE_SIZE,
    page: 1,
  });

  const [selectedDoctor, setSelectedDoctor] =
    useState<SelectDoctorStateProps | null>();

  const onExportReport = async () => {
    try {
      await actionDownloadReport(
        {
          ...formValues,
          status:
            formValues?.status?.length > 0
              ? formValues.status.join(",")
              : undefined,
        },
        "relatorio-financeiro.csv"
      );
    } catch (err) {
      console.error(err);
    }
  };

  const onChangePage = async (page: number) => {
    if (page) {
      setFormValues({ ...formValues, page });
    }

    await fetchData(page);
  };

  const fetchData = async (page?: number) => {
    try {
      const response = await actionListFinancialStatement({
        ...formValues,
        status:
          formValues?.status?.length > 0
            ? formValues.status.join(",")
            : undefined,
        page: page ?? formValues.page,
      });

      const result = response.items.map((item) => ({
        id: item.id,
        patient_name: patientItemName(item.patient_name, String(item.id)),
        doctor_name: doctorItemName(item.doctor_name, String(item.id)),
        type: typeItem(item.type, String(item.id)),
        amount_paid: amountPaidItem(item.amount_paid, String(item.id)),
        date: scheduleDate(item.start, String(item.id)),
      }));
      setRawData(response.items);
      setData(result);
      setMeta(response.meta);
    } catch (err) {
      console.error(err);
    }
  };

  const isDoctor = (): Boolean => UserIdentity.hasRole([UserRoleEnum.DOCTOR]);

  useEffect(() => {
    fetchData();
  }, []);

  const onViewSchedule = (scheduleId: string) => {
    const result = rawData?.find((item) => item.id === Number(scheduleId));
    if (result) {
      setSelectedSchedule(result);
    }
  };

  const patientItemName = (name: string, scheduleId: string) => {
    return (
      <div className="w-100">
        <span>{name}</span>
      </div>
    );
  };

  const doctorItemName = (name: string, scheduleId: string) => {
    return (
      <div className="w-100">
        <span>{name}</span>
      </div>
    );
  };

  const typeItem = (type: number, scheduleId: string) => {
    return (
      <div className="w-100">
        <Badge
          color={ScheduleTypeAlias(type as unknown as ScheduleTypeEnum)}
          className="px-3 py-2 fs-10"
        >
          {ScheduleTypeLabels(type as unknown as ScheduleTypeEnum)}
        </Badge>
      </div>
    );
  };

  const scheduleDate = (date: string, scheduleId: string) => {
    return (
      <div className="w-100">
        <span>{dateFormatter(date)}</span>
      </div>
    );
  };

  const amountPaidItem = (amountPaid: number, scheduleId: string) => {
    return (
      <div className="w-100">
        {amountPaid ? (
          <span>{labelMaskFormatToReal(String(amountPaid))}</span>
        ) : (
          <span className="fs-14">Não Pago</span>
        )}
      </div>
    );
  };

  const optionsScheduleStatus = enumIterator(ScheduleStatusEnum).map((key) => ({
    value: ScheduleStatusEnum[key],
    label: ScheduleStatusLabels(
      ScheduleStatusEnum[key] as unknown as ScheduleStatusEnum
    ),
  }));

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  return (
    <>
      <div className="main-wrapper">
        <div className="d-flex col-12 flex-wrap">
          {!isDoctor() && (
            <div className="col-12 col-md-12 col-xl-4 px-2">
              <SelectDoctorState
                label="Profissional"
                selectedDoctor={selectedDoctor}
                setSelectedDoctor={(value: SelectDoctorStateProps) => {
                  setSelectedDoctor(value);
                  setFormValues({
                    ...formValues,
                    doctor_id: value?.value,
                  });
                }}
              />
            </div>
          )}

          <FormGroup className="col-12 col-md-12 col-xl-4 px-2">
            <Label htmlFor="name">Status</Label>
            <Select
              isMulti
              name="status"
              options={optionsScheduleStatus}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Selecione conjunto status"
              onChange={(value) => {
                setFormValues({
                  ...formValues,
                  status: value.map((item) => item.value),
                });
              }}
            />
          </FormGroup>
          <FormGroup className="col-12 col-md-6 col-xl-2 px-2">
            <Label htmlFor="name">Data Inicial</Label>
            <DatePicker
              locale="pt-BR"
              dateFormat="dd/MM/yyyy"
              className="form-control"
              wrapperClassName="w-100"
              selected={startDate}
              onChange={(date: Date) => {
                if (!date) return;

                const dateFormatted = new Date(date);
                dateFormatted.setDate(dateFormatted.getDate() - OFFSET_DAYS);
                dateFormatted.setHours(21, 0, 0, 0);

                setStartDate(date);
                setFormValues({
                  ...formValues,
                  start: dateFormatted,
                });
              }}
            />
          </FormGroup>

          <FormGroup className="col-12 col-md-6 col-xl-2 px-2">
            <Label htmlFor="name">Data Final</Label>
            <DatePicker
              locale="pt-BR"
              dateFormat="dd/MM/yyyy"
              className="form-control"
              wrapperClassName="w-100"
              selected={endDate}
              onChange={(date: Date) => {
                if (!date) return;

                const dateFormatted = new Date(date.setHours(20, 59, 0, 0));
                setEndDate(date);
                setFormValues({
                  ...formValues,
                  end: dateFormatted,
                });
              }}
            />
          </FormGroup>
        </div>

        <div className="d-flex col-12 flex-wrap justify-content-end">
          <FormGroup className="col-12 col-md-6 col-xl-2 px-2 ">
            <Button
              color="primary"
              className="form-control"
              onClick={() => fetchData()}
            >
              Filtrar
            </Button>
          </FormGroup>
          <FormGroup className="col-12 col-md-6 col-xl-2 px-2 ">
            <Button
              color="secondary"
              className="form-control"
              onClick={onExportReport}
            >
              Exportar Relatório
            </Button>
          </FormGroup>
        </div>
        <div>
          <ListFinancialStatementTable
            schedules={data}
            meta={meta}
            onSelectSchedule={onViewSchedule}
            onChangePage={onChangePage}
            tableType={tableType}
            setTableType={setTableType}
          />
        </div>

        <Modal
          isOpen={selectedSchedule !== null}
          toggle={() => setSelectedSchedule(null)}
          size="md"
        >
          <ModalHeader toggle={() => setSelectedSchedule(null)}>
            Detalhes
          </ModalHeader>
          <ModalBody>
            <FinancialStatementDetail data={selectedSchedule} />
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};
