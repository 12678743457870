import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { MedicalRecordActions } from "./medical-record-actions/medical-record-actions";
import { PatientHeaderInfo } from "./patient-header-info/patient-header-info";
import { StartAppointment } from "./start-appointment/start-appointment";

import { actionFetchPatient } from "actions/patients/action-fetch-patient";
import { errorMessage } from "helpers/toast";

import "./medical-record.css";
import { MedicalRecordHistory } from "./start-appointment/medical-record-history/medical-record-history";
import { actionStartPrescription } from "actions/eletronic-prescription/action-start-prescription";
import { StartPrescription } from "./start-prescription/start-prescription";
import { ErrorCodeException } from "domain/error-codes/error-code-exception";
import ErrorCodesEnum from "domain/error-codes/error-code.enum";

export interface PatientInfo {
  id: number;
  name: string;
  birthdate?: Date | null;
  phone: string;
  document?: string;
  email?: string;
  firstAppointment?: Date | null;
  lastAppointment?: Date | null;
}

export const MedicalRecord: React.FC = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  const [patientData, setPatientData] = useState<PatientInfo>({
    id: 0,
    name: "",
    birthdate: new Date(),
    phone: "",
    document: "",
    email: "",
    firstAppointment: new Date(),
    lastAppointment: new Date(),
  });

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const fetchPatient = async () => {
    if (!id) {
      errorMessage("Paciente não encontrado");
      navigate("/pacientes");
      return;
    }
    try {
      const response = await actionFetchPatient(id);
      setPatientData({
        id: response.id,
        name: response.name,
        birthdate: response.birthdate,
        phone: response.phone,
        document: response.document_number,
        email: response.email,
        firstAppointment: response.first_appointment,
        lastAppointment: response.last_appointment,
      });
    } catch (error) {
      navigate("/pacientes");
      return;
    }
  };

  useEffect(() => {
    (async () => {
      fetchPatient();
    })();
  }, []);

  const onStartAppointment = () => {
    toggle();
  };

  const onSubmitAppointment = () => {};

  return (
    <>
      <div className="main-wrapper">
        <div className="mt-3">
          <PatientHeaderInfo {...patientData} />
          <div className="mt-4">
            {!modal && (
              <>
                <MedicalRecordActions onStartAppointment={onStartAppointment} />
                {id !== undefined && <MedicalRecordHistory id={Number(id)} />}
              </>
            )}
            {modal && (
              <StartAppointment
                onSubmit={onSubmitAppointment}
                patient={patientData}
                onCancelAppointment={toggle}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
